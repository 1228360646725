<template>
	<div class="bg_dark bg_intro_2">
		<div class="container">
			<div class="row">
				<div class="flex_wrap">
					<div class="intro_content maf003">
						<div class="intro_logo_title">
							<span class="hide">문화예술 전용 커뮤니티 마피아 프로젝트</span>
						</div>
					</div>
					<div class="intro_btn_wrap">
						<div class="btn_area">
						</div>
						<p class="copyright">Copyright © MAFI Inc. </p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'kakao',
		data: function(){
			return {
				code: this.$route.query.code
				, program: {
					name: '카카오 인증'
					, not_header: true
					, not_footer: true
				}
				, token_info: null
				, kakao_info: null
			}
		}
		, computed: {
			kakao_return_url: function(){

				let domain = process.env.VUE_APP_DOMAIN
				let dev = process.env.VUE_APP_DEV
				let local = 'https://localhost:8080'
				let location = window.location.href
				let url = ''

				console.log('kakao_return_url location', location)
				if(location.indexOf(domain) > -1){
					url = domain
				}else if(location.indexOf(dev) > -1){
					url = dev
				}else{
					url = local
				}

				return url + '/auth/login/kakao'
			}
		}
		, methods: {
			postLoginWithKakao: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$request.init({
						methods: 'post'
						, url: this.$api_url.api_path.post_kakao_login
						, data: {
							code: this.code
							, redirect_uri: this.kakao_return_url
						}
					})

					if(result.success){
						this.token_info = result.data
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
					if(process.env.VUE_APP_TYPE == 'sample'){
						const result = await this.$sample.postLoginWithKakao(this.code, this.kakao_return_url)
						console.log('result', result)
						if(result.success){
							if(result.data.is_out){
								this.$emit('to', { name: 'mafia009', params: { type: 'out'}})
							}else {
								this.$encodeStorage.setSessionAt(result.data)
								this.$emit('to', {name: 'index'})
								console.log('sample kakao login is success !!')
							}
						}else{
							this.$emit('to', { name: 'mafia009'})
						}
					}
				}
			}
		}

		, created() {
			this.$emit('onLoad', this.program)
			this.postLoginWithKakao()
		}
	}
</script>